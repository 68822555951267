import { useEffect, useState } from "react";
import { useAuth0, type User as Auth0User } from "@auth0/auth0-react";
import { datadogRum } from "@datadog/browser-rum";
import { useVendorContext, Vendor } from "@contexts/vendor-context";

const ERROR_IGNORE_LIST = ["ResizeObserver loop limit exceeded"];

async function initializeDatadog(user: Auth0User) {
  if (!user) {
    return;
  }

  // DG: I tried this but we ended up getting NOTHING into datadog
  // const isSesamyUser = !!user.email?.includes("@sesamy.com");

  if (
    process.env.NEXT_PUBLIC_VERCEL_ENV === "production"
    // && !isSesamyUser
  ) {
    datadogRum.init({
      applicationId: "c7fcdbcc-b66b-4f9c-b475-8a04f5c02ea8",
      clientToken: "pub2dfaadbfe92b58f818a76e0c6bad6dd4",
      site: "datadoghq.eu",
      service: "insights",
      env: "production",
      version: "1.0.0",
      sampleRate: 100,
      sessionReplaySampleRate: 100,
      trackInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
      beforeSend: (
        _,
        // this has type but they're wrong.  have to cast to any... typescript says error key doesn't exist but does from logs
        context: any,
      ) => {
        if (context.error && ERROR_IGNORE_LIST.includes(context.error)) {
          return false;
        }
      },
    });

    datadogRum.startSessionReplayRecording();

    datadogRum.setUser({
      sub: user.sub,
      email: user.email || undefined, // TypeScript dance for RUM. Can't get RUM's User type from package
      name: user.name || undefined,
    });
  }
}

const identifySegment = (user?: Auth0User, vendorSettings?: Vendor) => {
  if (!user) {
    return;
  }

  window?.analytics?.identify(
    user.sub,
    {
      name: user.name,
      email: user.email,
      company: vendorSettings && {
        id: vendorSettings.vendorId,
        name: vendorSettings.name,
      },
    },
    {
      Intercom: {
        user_hash: user.intercom_hash,
      },
    },
  );
};

export default () => {
  const { user } = useAuth0();
  const { selectedVendor, getVendorSettings } = useVendorContext();
  const vendorSettings = getVendorSettings(selectedVendor);
  const [hasInitializedDatadog, setHasInitializedDatadog] = useState(false);

  useEffect(() => {
    try {
      if (user && !hasInitializedDatadog) {
        setHasInitializedDatadog(true);
        initializeDatadog(user);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [user, hasInitializedDatadog]);

  useEffect(() => {
    identifySegment(user, vendorSettings);
  }, [user, vendorSettings]);

  return null;
};
