const sv = {
  translation: {
    date: "datum",
    bundle: "paket",
    netSalesInSek: "nettopris (SEK)",
    netSalesInEur: "nettopris (€)",
    newSubscriptions: "nya prenumerationer",
    canceledSubscriptions: "uppsägning prenumerationer",
    churnRate: "omsättningsgrad (%)",
    "payment.add_a_new_card": "Betalkort",
    n_items: "Antal enheter",
    n_podcast: "n podcasts",
    n_audiobook: "n ljudböcker",
    n_ebook: "n e-böcker",
    totalSales: "Total försäljning",
    churn_rate: "Omsättningsgrad",
    endedSubscriptions: "Avslutade prenumerationer",
    totalSubscriptions: "Totalt antal prenumerationer",
    total_subscribers: "Totalt antal prenumeranter",
    new_card: "Nytt kort",
    select_your_vendor: "Välj din leverantör",
    contact_support: "Behöver du hjälp?",
    copyright_sesamy: "©2023 Sesamy",
    vendor_access:
      "Din användare har tillgång till leverantörena nedan. Klicka på en för att välja.",
    sign_out: "Logga ut",
    use_another: "Jag vill använda ett annat konto",
    follow_real_time: "Följ din data i realtid.",
    more_revenue: "Medans du genererar mer inkomst.",
    vendor_logo: "{{vendor}}'s logotyp",
    type_to_filter: "Skriv för att filtrera",
    no_vendor_with_filter: "Ingen leverantör med det filtret",
    analytics: "Analys",
    overview: "Översikt",
    data_management: "Datahantering",
    customers: "Kunder",
    reports: "Rapporter",
    discounts: "Rabatter",
    discount: "Rabatt",
    podcasts: "Podcasts",
    paywalls: "Betalningsväggar",
    general: "Allmänt",
    single_purchase: "Engångsköp",
    shows: "Föreställningar",
    purchaseOptions: "Köpalternativ",
    add_paywall: "Lägg till betalningsvägg",
    paywall_settings: "Inställningar betalningsvägg",
    smart: "Smart",
    discount_stats: "Rabattstatistik",
    subscriptions: "Prenumerationer",
    single_purchases: "Enskilda köp",
    external_customers: "Externa kunder",
    contracts: "Kontrakt",
    contract: "Kontrakt",
    search: "Sök",
    tools: "Verktyg",
    privacy_policy: "Integritetspolicy",
    terms_and_conditions: "Villkor och bestämmelser",
    hi: "Hej",
    welcome_to: "Välkommen till",
    dashboard: "instrumentbräda",
    support_people: "en bild som visar support-personers avatarer",
    generate_report: "Generera rapport",
    created_reports: "Skapade rapporter",
    required: "krävd",
    is_required: "måste fyllas i",
    must_be_before_to_date: '"Från" datum måste vara före "Till" datum',
    must_be_after_from_date: '"Till" datum måste vara efter "Från" datum',
    fromDate: "Från",
    toDate: "Till",
    choose_data: "Välj vilken data du skulle vilja exportera",
    payment_method: "Betalningsmetod",
    status: "Status",
    amount: "Antal",
    create: "Skapa",
    update: "Uppdatera",
    cancel: "Avbryt",
    continue: "Fortsätt",
    skip: "Hoppa över",
    create_contract: "Skapa kontrakt",
    contract_setup_completed:
      "Kontraktsuppställningen är nu klar. Du kan skicka kassalänken till kunden så att de kan validera informationen och slutföra köpet.",
    allowed_create_contract:
      "Den valda betalningsmetoden gör att du kan slutföra avtalet direkt.",
    click_create_contract_to_complete:
      'Klicka på "Skapa kontrakt" för att slutföra skapandet. Kontraktet skapas direkt och betalningsinstruktionerna skickas till kunden.',
    complete_contract_now: "Slutför kontraktet direkt",
    deliveries: "Utskick",
    settings: "Inställningar",
  },
};

export default sv;
