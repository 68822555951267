import { SideNav } from "@type-defs/navigation";

export const getActivePageIds = (pathname: string) => {
  const [pageId, subPageId] =
    pathname && pathname.startsWith("/[vendor]")
      ? pathname.split("/").slice(2)
      : pathname.split("/").slice(1);

  const currentPage = navItems.find(({ id }) => id === pageId);
  const currentSubPage = currentPage?.subItems.find(
    ({ href }) => href === `/${pageId}/${subPageId}`,
  );

  let activeSubPageId = "";

  if (currentSubPage) {
    activeSubPageId = currentSubPage.id;
  } else {
    activeSubPageId = currentPage?.subItems[0].id || "";
  }

  return [pageId, activeSubPageId];
};

const navItems: SideNav = [
  {
    id: "analytics",
    icon: "analytics",
    scopes: [],
    subItems: [
      {
        id: "overview",
        href: "/analytics",
        scopes: [],
      },
      {
        id: "subscriptions",
        href: "/analytics/subscriptions",
        scopes: [],
      },
      {
        id: "single_purchases",
        href: "/analytics/purchases",
        scopes: [],
      },
      {
        id: "podcasts",
        href: "/analytics/podcasts",
        scopes: ["sesamy:developer"],
      },
    ],
  },
  {
    id: "podcasts",
    icon: "podcasts",
    scopes: ["podcasts:read", "podcasts:write"],
    subItems: [
      {
        id: "shows",
        href: "/podcasts/shows",
        scopes: [],
      },
      {
        id: "purchaseOptions",
        href: "/podcasts/purchase-options",
        scopes: [],
      },
    ],
  },
  {
    id: "paywalls",
    icon: "paywalls",
    scopes: ["paywall:read", "paywall:write"],
    subItems: [
      {
        id: "paywalls",
        href: "/paywalls",
        scopes: [],
      },
    ],
  },
  {
    id: "customers",
    icon: "customers",
    scopes: [],
    subItems: [
      {
        id: "customers",
        href: "/customers",
        scopes: [],
      },
      {
        id: "external_customers",
        href: "/customers/external-customers",
        scopes: ["external-origins:external-purchases:read"],
      },
      {
        id: "contracts",
        href: "/customers/contracts",
        scopes: [],
      },
      {
        id: "deliveries",
        href: "/customers/deliveries",
        scopes: ["fulfillment:read"],
      },
    ],
  },
  {
    id: "smart",
    icon: "smart",
    scopes: ["discounts:stats:read"],
    subItems: [
      {
        id: "discount",
        href: "/smart/discount",
        scopes: [],
      },
      {
        id: "analytics",
        href: "/smart/analytics",
        scopes: [],
      },
    ],
  },
  {
    id: "discounts",
    icon: "discounts",
    scopes: ["discounts:checkout:read"],
    subItems: [
      {
        id: "discounts",
        href: "/discounts",
        scopes: [],
      },
    ],
  },
  {
    id: "reports",
    icon: "reports",
    scopes: [],
    subItems: [
      {
        id: "reports",
        href: "/reports",
        scopes: [],
      },
    ],
  },
  {
    id: "tools",
    icon: "tools",
    scopes: [],
    subItems: [
      {
        id: "tools",
        href: "/tools",
        scopes: [],
      },
    ],
  },
  {
    id: "settings",
    icon: "settings",
    scopes: ["profile:vendors:read", "profile:vendor:manage-self"],
    subItems: [
      {
        id: "settings",
        href: "/settings/business",
        scopes: [],
      },
    ],
  },
];

// TODO: remove this and all *_FEATURE_FLAG from Vercel and check env.VERCEL_ENV !== 'production' instead
// Reason: less clutter and complexity
if (process.env.NEXT_PUBLIC_PRODUCT_SELECTOR_FEATURE_FLAG === "true") {
  navItems.push({
    id: "search",
    icon: "search",
    scopes: [],
    subItems: [
      {
        id: "search",
        href: "/search",
        scopes: [],
      },
    ],
  });
}

export default navItems;
