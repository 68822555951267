import "../styles/globals.css";
import { useState, useMemo } from "react";
import type { AppProps } from "next/app";
import { AuthTokenProvider } from "@contexts/auth-token-context";
import { SesamyAuthTokenProvider } from "@contexts/sesamy-auth-token-context";
import { VendorContentProvider } from "@contexts/vendor-context";
import { ModalContentProvider } from "@contexts/modal-context";
import { I18nextProvider } from "react-i18next";
import { createI18n } from "../i18/i18";
import { SelectedDatesProvider } from "@contexts/selected-dates-context";
import Layout from "@components/Ui/Layout";
import User from "@components/User";
import SWRWrapper from "@components/Context/SWRWrapper";
import { Router } from "next/router";
import Head from "next/head";
import { Auth0Provider } from "@auth0/auth0-react";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import Notifications from "@components/Ui/Notifications";

TimeAgo.setDefaultLocale(en.locale);
TimeAgo.addLocale(en);

Router.events.on("routeChangeComplete", () => {
  window?.analytics?.page();
});

if (!process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL) {
  throw new Error("Define NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL");
}
if (!process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID) {
  throw new Error("Define NEXT_PUBLIC_AUTH0_CLIENT_ID");
}

const MyApp = ({ Component, pageProps }: AppProps) => {
  const [language] = useState("en");
  const i18n = useMemo(() => createI18n(language), [language]);

  return (
    <I18nextProvider i18n={i18n}>
      <Auth0Provider
        domain={process.env.NEXT_PUBLIC_SESAMY_TOKEN_FULL_URL!}
        clientId="sesamy"
        authorizationParams={{
          redirect_uri:
            typeof window !== "undefined" ? window.location.origin : "",
        }}
      >
        <AuthTokenProvider>
          <VendorContentProvider>
            <SesamyAuthTokenProvider>
              <SelectedDatesProvider>
                <SWRWrapper>
                  <ModalContentProvider>
                    <Head>
                      <title>Sesamy Portal</title>
                    </Head>
                    <User />
                    <Layout>
                      <Component {...pageProps} />
                    </Layout>
                    <div id="drawer-root" />
                    <div id="modal-root" />
                    <Notifications />
                  </ModalContentProvider>
                </SWRWrapper>
              </SelectedDatesProvider>
            </SesamyAuthTokenProvider>
          </VendorContentProvider>
        </AuthTokenProvider>
      </Auth0Provider>
    </I18nextProvider>
  );
};

export default MyApp;
